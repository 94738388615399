window.onload = function() {
    
    // Open Hubspot Chatbot
    const chatbotButton = document.querySelector('.open-hs-chatbot');
    if (chatbotButton) {
        chatbotButton.onclick = function() {
            window.HubSpotConversations.widget.open();
        };
    }
    
};